<template>
  <div>
    <v-dialog v-model="changeOwnerDialog" persistent width="500px"
      ><v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text">Change Ownership</v-toolbar-title>
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-form ref="form">
          <v-row class="mx-3" no-gutters>
            <v-col cols="12" md="12" sm="12" lg="12" xl="12">
              <v-autocomplete
                class="mt-3"
                label="E-Mail *"
                v-model="email"
                :items="emailItems"
                item-text="user_email_id"
                item-value="user_email_id"
                dense
                outlined
                :rules="email_rules"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mt-n5" no-gutters>
            <v-card-text align="center">
              <v-btn
                :loading="adminLoading"
                @click="validate_change_owner()"
                class="gradient-bg white--text"
              >
                <v-icon class="mr-2">mdi-account-convert</v-icon>Change Owner
              </v-btn>
            </v-card-text>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { ChangeOfOwnerShip } from "@/graphql/mutations.js";
export default {
  components: { SnackBar },
  props: { changeOwnerDialog: Boolean, adminObject: Object, AdminItems: Array },
  data: () => ({
    SnackBarComponent: {},
    adminLoading: false,
    email: "",
    email_rules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    emailItems: [],
  }),
  watch: {
    changeOwnerDialog(val) {
      if (val == true) {
        // console.log("adminObject", this.adminObject);
        // console.log("admins items", this.AdminItems);
        
        this.emailItems = this.AdminItems.filter(
          (admin) => admin.user_type != "OWNER"
        );
      }
    },
  },
  methods: {
    async change_ownership() {
      this.adminLoading = true;
      try {
        var inputParams = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          user_email_id: this.email,
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(ChangeOfOwnerShip, {
            input: inputParams,
          })
        ).then((res) => {
          // console.log("res", res);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.ChangeOfOwnerShip.Message,
          };
          this.adminLoading = false;
          this.$emit("get_admins");
          this.close_dialog();
        });
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.adminLoading = false;
      }
    },
    close_dialog() {
      this.$refs.form.resetValidation();
      this.email = "";
      this.emailItems = [];
      this.$emit("close_change_owner");
    },
    validate_change_owner() {
      if (this.$refs.form.validate()) {
        // console.log("ji");
        this.change_ownership();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
  },
};
</script>

<style>
</style>