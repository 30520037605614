<template>
  <div>
    <v-dialog v-model="addAdminDialog" persistent width="550px">
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text">Add Administrator</v-toolbar-title>
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-form ref="form">
          <v-row class="mt-5" no-gutters>
            <v-col cols="12" md="12" sm="12" lg="12" xl="12" xs="12">
              <v-toolbar dense class="elevation-0">
                <v-autocomplete
                  label="Select Member"
                  v-model="member_name"
                  :items="memberItems"
                  dense
                  :rules="member_rules"
                  outlined
                  item-text="user_email"
                  item-value="user_email_id"
                  hint="Member must have an e-mail to add him/her as an admin"
                  :persistent-hint="true"
                ></v-autocomplete>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-card-text align="center">
              <v-btn
                :loading="adminLoading"
                @click="validate_add_admin()"
                class="gradient-bg white--text"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>Add Administrator
              </v-btn>
            </v-card-text>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { AddMembersAsAdmin } from "@/graphql/mutations.js";
import { list_users_master } from "@/graphql/queries.js";
import SnackBar from "@/components/SnackBar.vue";
import Overlay from "@/components/Overlay.vue";
export default {
  components: { SnackBar, Overlay },
  props: {
    addAdminDialog: Boolean,
  },
  data: () => ({
    member_name: "",
    memberItems: [],
    email_rules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    adminLoading: false,
    SnackBarComponent: {},
    member_id: "",
    overlay: false,
    email: "",
    member_rules: [(x) => x.trim() != "" || "Select Member to continue"],
  }),
  watch: {
    addAdminDialog(val) {
      if (val == true) {
        this.overlay = true;
        this.get_members_by_status();
        setTimeout(() => (this.overlay = false), 510);
      }
    },
  },
  methods: {
    validate_add_admin() {
      if (this.$refs.form.validate()) {
        this.adminLoading = true;
        this.add_admin();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async get_members_by_status() {
      this.isLoadingMember = true;
      this.overlay = true;
      try {
        this.MembersItems = [];
        let result = await API.graphql(
          graphqlOperation(list_users_master, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              action_type: "MEMBERS_ONLY",
            },
          })
        );
        var response = JSON.parse(result.data.list_users_master);
        if (response.Status == "SUCCESS") {
          var array = response.data.filter(
            (val) =>
              val.user_email_id != null &&
              val.user_email_id != undefined &&
              val.user_email_id != "null" &&
              val.user_email_id != ""
          );
          array.forEach((element) => {
            this.memberItems.push({
              user_email:
                element.user_name + " " + "(" + element.user_email_id + ")",
              user_email_id: element.user_email_id,
            });
          });
        } else {
          this.memberItems = [];
        }
        // this.memberItems = result.data.ListAllUsers.ActiveMembers.filter(
        //   (val) =>
        //     val.user_email_id != null &&
        //     val.user_email_id != undefined &&
        //     val.user_email_id != "null" &&
        //     val.user_email_id != ""
        // );
        this.isLoadingMember = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.isLoadingMember = false;
        this.overlay = false;
      }
    },
    async add_admin() {
      this.adminLoading = true;
      try {
        var inputParams = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          user_email_id: this.member_name,
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(AddMembersAsAdmin, {
            input: inputParams,
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.AddMembersAsAdmin.Message,
          };
          this.adminLoading = false;
          this.$emit("get_admins");
          this.close_dialog();
        });
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.adminLoading = false;
      }
    },
    close_dialog() {
      this.member_name = "";
      this.$refs.form.resetValidation();
      this.$emit("close_add_admin_dialog");
    },
  },
};
</script>

<style>
</style>