<template>
  <div>
    <v-card flat class="mt-2">
      <v-row no-gutters class="mt-4">
        <v-col class="mt-3" cols="12" md="12" sm="12" lg="12" xl="12">
          <v-toolbar dense class="elevation-0">
            <v-text-field
              label="Search"
              class=""
              v-model="search"
              append-icon="mdi-magnify"
              style="max-width: 220px"
              dense
            ></v-text-field>
            <v-spacer />
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="open_admin_dialog()"
                  small
                  v-on="on"
                  class="gradient-bg white--text mt-n6"
                  ><v-icon>mdi-plus</v-icon>Add Administrator
                </v-btn>
              </template>
              <span class="white--text">Add Administrator</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :height="height"
          :headers="AdminData"
          :items="AdminItems"
          :items-per-page="100"
          :loading="isLoadingAdmin"
          loading-text="Loading... Please wait"
          class="overflow-y-auto mx-4"
          :fixed-header="fixed"
          :search="search"
          dense
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text"
              >No Administrators available</v-alert
            >
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip
              v-if="
                item.user_type != 'OWNER' &&
                item.user_email_id != $store.getters.get_useremail
              "
              bottom
              color="primary"
            >
              <template v-slot:activator="{ on }">
                <a @click="delete_admin(item)">
                  <v-icon small color="primary" v-on="on">mdi-delete </v-icon>
                </a>
              </template>
              <span class="white--text"
                >Delete Administrator Permanently ?
              </span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click="call_change_owner(item)">
                  <v-icon
                    v-if="
                      item.user_type == 'OWNER' &&
                      $store.getters.get_useremail == item.user_email_id
                    "
                    small
                    color="primary"
                    v-on="on"
                    >mdi-account-convert
                  </v-icon>
                </a>
              </template>
              <span class="white--text">Transfer Ownership ? </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.admin_created_on`]="{ item }">
            <span v-if="item.admin_created_on!=undefined" v-text="get_date(item.admin_created_on/1000)"></span>
            <span v-else class="ml-4">-</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
    <AddAdministrator
      @close_add_admin_dialog="addAdminDialog = false"
      :addAdminDialog="addAdminDialog"
      @get_admins="get_admins"
    />
    <ChangeOwner
      :changeOwnerDialog="changeOwnerDialog"
      @close_change_owner="changeOwnerDialog = false"
      :adminObject="adminObject"
      :AdminItems="AdminItems"
      @get_admins="get_admins"
    />
    <DeleteDialog
      :confDeleteDialog="confDeleteDialog"
      @close_conf_delete_dialog="confDeleteDialog = false"
      :deleteText="deleteText"
      :deleteIcon="deleteIcon"
      :deleteColor="deleteColor"
      @success_conf_delete="deleteSuccessAction"
      :deleteLoading="deleteLoading"
    />
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { UpdateAdminToMember } from "@/graphql/mutations.js";
import { list_users_master } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import AddAdministrator from "@/components/Dialogs/AddAdministrator.vue";
import ChangeOwner from "@/components/Dialogs/ChangeOwner.vue";
import DeleteDialog from "@/components/Dialogs/ConfirmationDialogs/DeleteDialog.vue";

export default {
  components: {
    Overlay,
    AddAdministrator,
    ChangeOwner,
    DeleteDialog,
  },
  data: () => ({
    deleteLoading: false,
    confDeleteDialog: false,
    search: "",
    adminObject: {},
    fixed: true,
    height: 0,
    AdminData: [
      { text: "Name", value: "user_name" },
      { text: "E-Mail", value: "user_email_id" },
      { text: "Created On", value: "admin_created_on" },

      { text: "Actions", value: "Actions" },
    ],
    AdminItems: [],
    overlay: false,
    isLoadingAdmin: false,
    addAdminDialog: false,
    changeOwnerDialog: false,
    deleteText: "",
    deleteIcon: "",
    deleteColor: "",
  }),
  created() {
    this.overlay = true;
    this.height = window.innerHeight - 220;
    this.get_admins();
  },
  methods: {
    get_date(date) {
      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      let a = new Date(date * 1000).toLocaleString("en-US", options);

      // Splitting the date into components
      const [datePart, timePart] = a.split(", ");
      const [month, day, year] = datePart.split("/");

      // Reformatting to "dd/mm/yyyy HH:MM:SS" format
      return `${day}/${month}/${year} ${timePart}`;
    },
    delete_admin(item) {
      // console.log("item,item", item);
      this.deleteUserItem = item;
      // console.log(item);
      this.confDeleteDialog = true;
      this.deleteText = `Are you sure you want to delete this admin ${item.user_email_id}`;
      this.deleteIcon = "mdi-account-off";
      this.deleteColor = "red";
    },
    deleteSuccessAction() {
      this.deleteLoading = true;
      this.delete_admin_permanently(this.deleteUserItem);
    },
    async delete_admin_permanently(item) {
      this.deleteLoading = true;
      try {
        await API.graphql(
          graphqlOperation(UpdateAdminToMember, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              user_email_id: item.user_email_id,
            },
          })
        ).then((res) => {
          this.$store.commit("snackbar/showMessage", {
            content: res.data.UpdateAdminToMember.Message,
            color: "green",
          });
          this.get_admins();
          this.deleteLoading = false;
          this.confDeleteDialog = false;
        });
      } catch (err) {
        console.log(err);
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        this.deleteLoading = false;
      }
    },
    call_change_owner(item) {
      this.adminObject = item;
      this.changeOwnerDialog = true;
    },
    open_admin_dialog() {
      this.addAdminDialog = true;
    },
    async get_admins() {
      this.isLoadingAdmin = true;
      this.overlay = true;
      try {
        this.MembersItems = [];
        let result = await API.graphql(
          graphqlOperation(list_users_master, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              action_type: "ADMINS_ONLY",
            },
          })
        );
        // console.log(JSON.parse(result.data.list_users_master));
        var response = JSON.parse(result.data.list_users_master);
        if (response.Status == "SUCCESS") {
          this.AdminItems = response.data;
        } else {
          this.AdminItems = [];
        }
        // console.log(" this.MembersItems ", this.AdminItems);

        this.isLoadingAdmin = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.isLoadingAdmin = false;
        this.overlay = false;
      }
    },
  },
};
</script>

<style>
</style>