var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":""}},[_c('v-text-field',{staticStyle:{"max-width":"220px"},attrs:{"label":"Search","append-icon":"mdi-magnify","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"gradient-bg white--text mt-n6",attrs:{"small":""},on:{"click":function($event){return _vm.open_admin_dialog()}}},on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Add Administrator ")],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Add Administrator")])])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto mx-4",attrs:{"height":_vm.height,"headers":_vm.AdminData,"items":_vm.AdminItems,"items-per-page":100,"loading":_vm.isLoadingAdmin,"loading-text":"Loading... Please wait","fixed-header":_vm.fixed,"search":_vm.search,"dense":"","footer-props":{
          'items-per-page-options': [10, 15, 20, 25],
        }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v("No Administrators available")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.Actions",fn:function(ref){
        var item = ref.item;
return [(
              item.user_type != 'OWNER' &&
              item.user_email_id != _vm.$store.getters.get_useremail
            )?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('a',{on:{"click":function($event){return _vm.delete_admin(item)}}},[_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-delete ")])],1)]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Delete Administrator Permanently ? ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('a',{on:{"click":function($event){return _vm.call_change_owner(item)}}},[(
                    item.user_type == 'OWNER' &&
                    _vm.$store.getters.get_useremail == item.user_email_id
                  )?_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-account-convert ")]):_vm._e()],1)]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Transfer Ownership ? ")])])]}},{key:"item.admin_created_on",fn:function(ref){
                  var item = ref.item;
return [(item.admin_created_on!=undefined)?_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.admin_created_on/1000))}}):_c('span',{staticClass:"ml-4"},[_vm._v("-")])]}}],null,true)})],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('AddAdministrator',{attrs:{"addAdminDialog":_vm.addAdminDialog},on:{"close_add_admin_dialog":function($event){_vm.addAdminDialog = false},"get_admins":_vm.get_admins}}),_c('ChangeOwner',{attrs:{"changeOwnerDialog":_vm.changeOwnerDialog,"adminObject":_vm.adminObject,"AdminItems":_vm.AdminItems},on:{"close_change_owner":function($event){_vm.changeOwnerDialog = false},"get_admins":_vm.get_admins}}),_c('DeleteDialog',{attrs:{"confDeleteDialog":_vm.confDeleteDialog,"deleteText":_vm.deleteText,"deleteIcon":_vm.deleteIcon,"deleteColor":_vm.deleteColor,"deleteLoading":_vm.deleteLoading},on:{"close_conf_delete_dialog":function($event){_vm.confDeleteDialog = false},"success_conf_delete":_vm.deleteSuccessAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }